import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import background from "../assets/images/background-login.jpg";
import eyes from "../assets/images/eyes.png";
import "../pages/Login.css";

const Login = () => {
    return (
        <div class="container" style={styles.backgroundImage}>
            <div class="login-box" style={styles.container}>
                <div class="logo" style={styles.logo}>

                </div>
                <h2 style={styles.h2}>Đăng nhập</h2>
                <form action="#">
                    <div class="block-username" style={styles.alignLeft}>
                        <label for="username" style={styles.block}>Tên đăng nhập</label>
                        <input type="text" id="username" placeholder="Nhập số điện thoại" style={styles.input} />
                    </div>
                    <div class="block-password" style={styles.alignLeft}>
                        <label for="password" style={styles.block}>Mật khẩu</label>
                        <div class="password-wrapper" style={styles.passworWrapper}>
                            <input type="password" id="password" placeholder="Nhập mật khẩu" style={styles.input} />
                            <span class="toggle-password" style={styles.passworShow}><img src={eyes} alt="eyes.png" /></span>
                        </div>
                    </div>
                    <button type="submit" class="login-button" style={styles.loginButton}>Đăng nhập</button>
                </form>
            </div>
        </div>
    );
};

const styles = {
    logo: {
        marginTop: "40px",
    },
    h2: {
        fontFamily: "Inter",
        fontSize: "26px",
        fontWeight: "600",
        lineHeight: "31.47px",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
        color: "#08142D",
    },
    backgroundImage: {
        backgroundImage: `url(${background})`,
        maxHeight: "926px",
        height: "926px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        maxWidth: "500px",
        width: "500px",
        margin: "auto",
        backgroundColor: "#FFFFFF",
        maxHeight: "500px",
        height: "500px",
        borderRadius: "16px",
        textAlign: "center",
    },
    alignLeft: {
        textAlign: "left",
        margin:"0 40px 16px",
    },
    block: {
        display: "block",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "14.52px",
        textAlign: "left",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
        color: "#51607B",
        marginBottom: "12px",
    },
    input: {
        width: "100%",
        height: "24px",
        borderBottom: "1px solid #DAE0EB",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "16.94px",
        textAlign: "left",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
        color: "#000000",
    },
    passworWrapper: {
        position: "relative",
    },
    passworShow: {
        position: "absolute",
        right: "0",
        top: "5px",
    },
    loginButton: {
        width: "420px",
        height: "48px",
        padding: "15px 0px",
        borderRadius: "8px",
        border: "none",
        background: "linear-gradient(258.94deg, #1ADDD2 0%, #0094CC 100.81%)",
        fontFamily: "Inter",
        fontSize: "15px",
        fontWeight: "700",
        lineHeight: "18.15px",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
        color: "#fff",
        cursor: "pointer",
        marginTop: "55px",
    },
};

export default Login;
